<template>
  <vs-card>
    <div slot="header" class="w-full items-center flex justify-between">
      <h4 class="capitalize">
        Details
      </h4>
      <div class="flex items-center">
        <vs-button
          class=""
          icon="close"
          size="medium"
          @click="$emit('remove', index)"
        ></vs-button>
      </div>
    </div>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <vs-input
            :success="!errors.first('contact.name') && contact.name != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('contact.name') ? true : false"
            v-validate="'required'"
            name="name"
            data-vv-as="name"
            label="Name"
            class="w-full"
            data-vv-scope="contact"
            :danger-text="errors.first('contact.name')"
            v-model="contact.name"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <vs-input
          :success="!errors.first('contact.email') && contact.email != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('contact.email') ? true : false"
            v-validate="'required'"
            name="email"
            data-vv-as="email"
            label="Email"
            class="w-full"
            data-vv-scope="contact"
            :danger-text="errors.first('contact.email')"
            v-model="contact.email"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <vs-input
          :success="!errors.first('contact.address') && contact.email != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('contact.address') ? true : false"
            v-validate="'required'"
            name="address"
            data-vv-as="address"
            label="Address"
            class="w-full"
            data-vv-scope="contact"
            :danger-text="errors.first('contact.clinic')"
            v-model="contact.clinic"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <vs-input
            :success="!errors.first('contact.phone') && contact.phone != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('contact.phone') ? true : false"
            v-validate="'required'"
            name="phone"
            data-vv-as="phone"
            label="Contact"
            class="w-full"
            data-vv-scope="contact"
            :danger-text="errors.first('contact.phone')"
            v-model="contact.phone"
          />
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="12"
        class="vs-con-loading__container flex flex-col">
        <div class="flex flex-col gap-3 items-start justify-between mx-5 w-full h-full">
          <label class="text-base font-semibold">
            Icon 74x74 - <span class="text-xs">Display in partner page</span>
            <span v-if="imageErrors[0].icon" class="text-red-600">
              *Icon is required
            </span>
          </label>
          <div v-if="contact.icon" class="flex flex-col items-start gap-2">
            <img :src="contact.icon" alt="Uploaded" />
            <vs-button line-origin="center" color="dark" type="line" class="px-0 py-1 ml-1" @click="handleRemoveImage">Remove Image</vs-button>
          </div>
          <div v-else id="icon" class="customHeight w-3/4 sm:w-1/2 text-center relative">
            <div
              class="border-2 border-dashed border-gray-500 p-5 
                h-full w-full absolute flex flex-col gap-3 
                items-center justify-center"
              @dragover.prevent
              @drop="handleDrop($event)"
            >
              <feather-icon icon="UploadIcon" class="text-primary2" />
              <p class="text-primary2 text-xl">Drop files to upload</p>
              <p class="text-primary2 text-lg">or</p>
              <input type="file" ref="fileInputIcon" hidden @change="handleFileChange($event)" accept="image/*" />
              <vs-button @click="clickUploadImage('fileInputIcon')" class="bg-primary2 w-1/2" :key="1">Select Files</vs-button>
              <p class="text-lg font-light">Recommended Image Size</p>
              <p class="text-lg font-light">74 x 74</p>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
import axios from "@/axios";

export default {
  props: ["topics", "index", "topicIndex", "partnerIndex", "contact"],
  data() {
    return {
      imageErrors: [
        {
          icon: '',
        }
      ],
    }
  },
  methods: {
    handleRemoveImage() {
      this.topics[this.topicIndex].page.partners[this.partnerIndex].contacts[this.index].icon = ""
    },
    handleDrop(event) {
      event.preventDefault();
      this.uploadImage(event.dataTransfer.files[0]);
    },
    handleFileChange(event) {
      this.uploadImage(event.target.files[0]);
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#icon",
        scale: 0.6,
      });
      let data = new FormData();
      data.append("profileImage", event);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          this.topics[this.topicIndex].page.partners[this.partnerIndex].contacts[this.index].icon = res.data.Location;
          this.$vs.loading.close("#icon" + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#icon" + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    clickUploadImage(refName) {
      this.$refs[refName].click();
    },
    async handleFormValidation() {
      let results = []
      const isValid = await this.$validator.validateAll("contact");

      // form checker
      results.push(isValid)

      // icon checker
      if (!this.topics[this.topicIndex].page.partners[this.partnerIndex].contacts[this.index].icon) {
        this.imageErrors[0].icon = "error"
        results.push(false)
      }

      return !results.some(bool => !bool)
    },
  },
}
</script>

<style lang="scss">
.customHeight {
  height: 55dvh;
}
</style>