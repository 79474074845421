<template>
  <vs-card>
    <div slot="header">
      <vs-row>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <h3>Resource Hub - Json Setting</h3>
          </div>
          <div class="w-full m-5" align="right">
            <vs-button @click="save">Update</vs-button>
          </div>
        </vs-col>
      </vs-row>
    </div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <el-input
            id="jsonSettings"
            class="max-w-xl border-black"
            type="textarea"
            autosize
            :rows="1"
            v-model="value"
          >
          </el-input>
        </div>
      </vs-col>
    </vs-row>
    <vs-divider />
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <div class="m-5">
        <p class="text-2xl pb-2"><strong>Topic Details</strong></p>
        <p class="text-sm">List of topics & header showed in nurse/basic/org-owner/doctor resource hub page. </p>
      </div>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <vs-input
            :success="!errors.first('topic.name') && topicTitle != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('topic.name') ? true : false"
            v-validate="'required'"
            name="topicTitle"
            data-vv-as="topicTitle"
            label="Section Topic Title"
            class="w-full"
            data-vv-scope="topic"
            :danger-text="errors.first('topic.name')"
            v-model="topicTitle"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full m-5">
          <vs-card class="topic-wrapper">
            <div slot="header" class="w-full items-center flex justify-between">
              <h4 class="capitalize">
                Topics
              </h4>
              <div class="flex items-center">
                <vs-button class="mr-2" size="medium" @click="addTopic"
                  >Add</vs-button
                >
              </div>
            </div>
            <draggable
              v-model="topics"
              @start="dragging = true"
              @end="dragging = false"
              v-bind="dragOptions"
            >
            <transition-group type="transition" name="flip-list">
              <div v-for="(topic, index) in topics" :key="index">
                <Topic
                  :topic="topic"
                  :index="index"
                  :topics="topics"
                  :is-dragging="isDragging"
                  @remove="removeTopic"
                  @validateForm="validateForm"
                  ref="refTopic"
                />
              </div>
            </transition-group>
            </draggable>
          </vs-card>
        </div>
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions } from "vuex";
import Topic from "./Topic.vue"

const DEFAULT_TEMPLATE = {
  "main_banner": {
    "header": "",
    "subHeader": "",
    "image": ""
  },
  "first_section": {
    "header": "",
    "tag": ""
  },
  "document_icon": "",
  "recent_resources_text": "",
  "supplier_training": {
    "desktop_image": "",
    "mobile_image": "",
    "link": "",
  }
}

export default {
  components: {
    draggable,
    Topic
  },
  data() {
    return {
      value: "",
      key: "resourceHubSetting",
      topics: [],
      topicTitle: "",
      isValidForm: false,
      dragging: false
    };
  },
  methods: {
    ...mapActions("admin", ["getSettingsByKey", "updateSettingsByKey"]),
    async save() {
      try {
        this.$vs.loading()

        const res = await this.validateTopicForm()

        if (res && res.includes(false)) {
          setTimeout(() => {
            this.$vs.loading.close();
          }, 200);
          return this.$vs.notify({
            title: "Error",
            text: "Fill in required fields.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }

        await this.updateSettingsByKey({
          value: JSON.stringify({
            ...JSON.parse(this.value),
            popular_category_text: this.topicTitle,
            popular_categories: this.topics
          }).replace(/style="/g, '_style="'),
          key: this.key
        })

        this.$vs.notify({
          title: "Success",
          text: "Settings Saved.",
          color: "success",
        });
      } catch (error) {
        console.log(`Error updating resource hub setting: ${error}`)
        this.$vs.notify({
          title: "Failed to save settings.",
          text: "Please try again later.",
          color: "danger",
        });
      } finally {
        this.$vs.loading.close()
      }
    },
    async fetchSetting() {
      try {
        this.$vs.loading()

        const { data } = await this.getSettingsByKey(this.key)
        let defaultValue = DEFAULT_TEMPLATE

        if (Object.keys(JSON.parse(data.data.value)).length) {
          defaultValue = JSON.parse(data.data.value)

          // topicTitle = popular_category_text
          if (defaultValue.popular_category_text) {
            this.topicTitle = defaultValue.popular_category_text
            delete defaultValue.popular_category_text
          }

          // topics = popular_categories
          if (defaultValue.popular_categories) {
            this.topics = defaultValue.popular_categories
            delete defaultValue.popular_categories
          }
        }

        this.value = JSON.stringify(defaultValue, null, 2);
      } catch (error) {
        console.log(`Error fetching resource hub setting: ${error}`)
      } finally {
        this.$vs.loading.close()
      }
    },
    removeTopic(index) {
      this.topics.splice(index, 1)
    },
    addTopic() {
      this.topics.push({
        label: "",
        value: "",
        page:{
          header: "",
          subHeader: "",
          partners: [],
          image: ""
        },
        icon: ""
      })
    },
    validateForm(isValid) {
      this.isValidForm = isValid
    },
    async validateTopicForm() {
      if (this.$refs.refTopic) {
        return Promise.all(
          this.$refs.refTopic.map(async component => {
            if (component.handleFormValidation) {
              return await component.handleFormValidation();
            }
          })
        )
      }
    },
   },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    isDragging() {
      return this.dragging ? true : false;
    },
  },
  created() {
    this.fetchSetting()
  },
}
</script>

<style lang="scss">
.el-input {
  width: 400px;
}

.el-textarea__inner:focus {
  border-color: black !important;
}

.topic-wrapper {
  .vs-textarea {
    height: 200px;
  }
}
</style>
